import { debounce } from 'debounce';
import request from './utils/request';
import style from './assets/css/index.scss';

function identity(arg) {
  let isIdentity = false;
  let timer = null;

  const {
    ele,
    posX,
    posY,
    login,
    zIndex = 2,
    getList,
    regLink,
    callback,
    hasGuide = false,
    regLinkShow = true,
    isHasPerson = true,
    listPosition = 'absolute',
    eleHoverClass = '',
    loginRequired,
    backgroundColor,
  } = arg;

  const styleIdentity = document.querySelector(`.${style.identity}`);

  if (isIdentity && styleIdentity) {
    clearInterval(timer);
    timer = setInterval(() => {
      if (styleIdentity) {
        clearInterval(timer);
        styleIdentity.style.zIndex = zIndex;
        if (backgroundColor) {
          styleIdentity.style.backgroundColor = backgroundColor;
        }
        styleIdentity.style.position = listPosition;
      }
    }, 300);
    return;
  }

  isIdentity = true;

  if (!ele) {
    window.alert('ele目标元素为必填');
    return;
  }

  const $ele = document.querySelector(ele);
  if (!$ele) {
    return;
  }
  $ele.classList.add(style.identityEle);

  const dev = /(\.t\.)/;
  const pre = /(\.p\.)/;
  const { hostname } = window.location;

  let open = 'https://open.nxin.com/api';
  let wo = 'http://wo.nxin.com';

  if (dev.test(hostname) || hostname === '127.0.0.1') {
    open = 'https://open.t.nxin.com/api';
    wo = 'http://wo.t.nxin.com';
  }

  if (pre.test(hostname)) {
    open = 'https://open.p.nxin.com/api';
    wo = 'http://wo.p.nxin.com';
  }

  request.get(`${open}/nxin.usercenter.user.get/1.0`).then((response) => {
    const { code, data } = response;

    if (code === 10011) {
      if (loginRequired) {
        window.alert('未登录');
        window.location.href = login;
      }
      $ele.innerHTML = '未登录';
      $ele.onclick = function () {
        window.location.href = login;
      };
      return;
    }

    const { idStr, realName, loginName } = data;

    request.get(getList || `${open}/nxin.usercenter.enterprise.list/5.0?source=0`).then((listResponse) => {
      let { data: listData } = listResponse;
      let current = '';
      const isDefault = listData.some(item => item.IsDefault === true);

      if (isDefault) {
        const curCompany = listData.filter(item => item.IsDefault === true);
        $ele.innerHTML = `<span title="${curCompany[0].Name}" class="${style.name}">${curCompany[0].Name}</span><i class="${style.arrow}"></i>`;
        isHasPerson && listData.unshift({
          ID: idStr,
          Name: realName || loginName,
          UserType: '0',
        });
        current = `<div class="${style.current} ${style.company}">
          <span class="${style.name}">${curCompany[0].Name}</span>
          <span class="${style.type}">&nbsp;&nbsp;(企业)</span>
          <span class="${style.auth}" style="display: ${curCompany[0].state === 8 ? 'inline' : 'none'}"></span>
        </div>`;
      } else {
        $ele.innerHTML = `<span class="${style.name}">${realName || loginName}</span><i class="${style.arrow}"></i>`;
        current = `<div class="${style.current} ${style.persons}">
          <span class="${style.name}">${realName || loginName}</span>
          <span class="${style.type}">&nbsp;&nbsp;(个人)</span>
        </div>`;
      }

      const list = listData.map(item => (!item.IsDefault ? `<a
        class="${item.state === 8 ? style.auth : ''}"
        type="${item.UserType === '0' ? '0' : '1'}"
        data=${JSON.stringify(item)}>
          <span>${item.Name}${item.UserType === '0' ? '&nbsp;&nbsp;(个人)' : ''}</span>
      </a>` : null));
      const listContent = list.length ? list.join('') : `<div class="${style.noSearch}">暂无企业</div>`;

      const $wrap = document.createElement('div');
      const regLinkDef = regLinkShow && (regLink || `${wo}/ent/register?retUrl=${window.location.href}`);
      let $content = `<div class="${style.title}"><span>当前身份</span></div>
        ${current}
        <div class="${style.title}"><span>选择切换身份</span></div>
        <div class="${style.filter}">
          <input type="text" placeholder="快速搜索" />
        </div>
        <div class="${style.list}">${listContent}</div>
      `;

      if (regLinkShow) {
        $content += `<a href="${regLinkDef}" target="_blank" class="${style.reg}">注册新企业</a>`;
      }

      $wrap.innerHTML = $content;
      $wrap.style.zIndex = zIndex;
      if (backgroundColor) {
        $wrap.style.backgroundColor = backgroundColor;
      }
      $wrap.classList.add(style.identity);
      $wrap.style.position = listPosition;
      document.body.appendChild($wrap);

      const $list = $wrap.querySelector(`.${style.list}`);
      const $filter = $wrap.querySelector(`.${style.filter} input`);

      // 企业搜索
      let $filterList = listData;
      /* eslint func-names: ["error", "never"] */
      $filter.oninput = debounce(function () {
        $filterList = listData.filter(item => item.Name.indexOf(this.value) !== -1
          && !item.IsDefault).map(item => `<a
          class="${item.state === 8 ? style.auth : ''}"
          type="${item.UserType === '0' ? '0' : '1'}"
          data=${JSON.stringify(item)}>
          <span>${item.Name}${item.UserType === '0' ? '&nbsp;&nbsp;(个人)' : ''}</span>
        </a>`);

        if ($filterList.length) {
          $list.innerHTML = $filterList.join('');
        } else {
          $list.innerHTML = `<div class="${style.noSearch}">暂无企业</div>`;
        }
      }, 500);

      // 设置位置
      let getBoundingClientRect = $ele.getBoundingClientRect();
      function setPos() {
        getBoundingClientRect = $ele.getBoundingClientRect();
        if (posX || posY) {
          if (posX) {
            $wrap.style.left = posX;
          }
          if (posY) {
            $wrap.style.top = posY;
          }
        } else {
          $wrap.style.top = `${getBoundingClientRect.top + getBoundingClientRect.height}px`;
          $wrap.style.left = `${getBoundingClientRect.left}px`;
        }
      }

      // 用户引导
      const isGuide = window.localStorage.getItem('companyGuide');
      if ((!isGuide && idStr && idStr !== '0') && hasGuide) {
        const $guideMask = document.createElement('div');
        const $guide = document.createElement('div');
        $guideMask.classList.add(style.identityMask);
        $guide.classList.add(style.identityGuide);
        $guide.innerHTML = `
          <a class="${style.close}"></a>
          <a class="${style.close2}">关闭</a>
        `;
        $guide.style.left = `${getBoundingClientRect.left - 160}px`;
        document.body.appendChild($guideMask);
        document.body.appendChild($guide);
        const $guideBtns = $guide.querySelectorAll('a');
        for (let i = 0, len = $guideBtns.length; i < len; i += 1) {
          $guideBtns[i].onclick = function () {
            $guideMask.style.display = 'none';
            $guide.style.display = 'none';
            window.localStorage.setItem('companyGuide', 1);
          };
        }
      }

      // 切换账户
      $list.onclick = ({ target }) => {
        const curData = JSON.parse(target.getAttribute('data') || target.parentNode.getAttribute('data'));
        const curId = curData.ID;
        const curType = target.getAttribute('type') || target.parentNode.getAttribute('type');
        const curDate = curData.EnterDate;
        if (curId) {
          const innerHTML = target.innerHTML.replace(/\(个人\)+/, '');
          $ele.innerHTML = `${innerHTML}<i class="${style.arrow}"></i>`;
          $wrap.style.display = 'none';
          let enterpriseUpdate = `${open}/nxin.usercenter.enterprise.update/1.0?enterpriseId=${curId}`;
          if (curDate !== 'undefined') {
            enterpriseUpdate += `&date=${curDate}`;
          }
          request.get(enterpriseUpdate).then((changeResponse) => {
            if (changeResponse.code === 10011) {
              window.location.href = login;
              return;
            }
            if (changeResponse.code === 0) {
              const $current = $wrap.querySelector(`.${style.current}`);
              if (curType === '1') {
                $current.classList.add(style.company);
                $current.classList.remove(style.persons);
                $current.innerHTML = `<span class="${style.name}">${curData.Name}</span>
                <span class="${style.type}">&nbsp;&nbsp;(企业)</span>
                <span class="${style.auth}" style="display: ${curData.state === 8 ? 'inline' : 'none'}"></span>`;
              } else {
                $current.classList.add(style.persons);
                $current.classList.remove(style.company);
                $current.innerHTML = `<span class="${style.name}">${realName || loginName}</span>
                <span class="${style.type}">&nbsp;&nbsp;(个人)</span>`;
              }
              listData = listData.map((item) => {
                if (item.ID === curId) {
                  item.IsDefault = true;
                } else {
                  item.IsDefault = false;
                }
                return item;
              });
              $filterList = listData;
              const $eleArr = listData.filter(item => item.ID !== curId).map(item => `<a
                class="${item.state === 8 ? style.auth : ''}"
                type="${item.UserType === '0' ? '0' : '1'}"
                data=${JSON.stringify(item)}>
                <span>${item.Name}${item.UserType === '0' ? '&nbsp;&nbsp;(个人)' : ''}</span>
              </a>`);

              if ($eleArr.length) {
                $list.innerHTML = $eleArr.join('');
              } else {
                $list.innerHTML = `<div class="${style.noSearch}">暂无企业</div>`;
              }
              if (callback) {
                callback({
                  id: curId,
                  type: curType,
                });
              }
            }
          });
        }
      };

      // 悬停显示
      $ele.onmouseover = function () {
        $wrap.style.display = 'block';
        setPos();
      };
      $wrap.onmouseover = () => {
        $wrap.style.display = 'block';
        $ele.classList.add(style.cur);
        if (eleHoverClass !== '') {
          $ele.classList.add(eleHoverClass);
        }
      };

      // 离开隐藏
      $ele.onmouseout = () => {
        $wrap.style.display = 'none';
      };
      $wrap.onmouseout = () => {
        $wrap.style.display = 'none';
        $ele.classList.remove(style.cur);
        if (eleHoverClass !== '') {
          $ele.classList.remove(eleHoverClass);
        }
      };
    });
  });
}

window.NxinPlug = {
  ...window.NxinPlug,
  identity,
};

export default { NxinPlug: { identity } };
