import axios from 'axios';

const request = axios.create({
  withCredentials: true,
});

request.interceptors.response.use((response) => {
  const { data } = response;
  if (data.code !== 0 && data.code !== 10011) {
    window.alert(data.msg);
    return Promise.reject();
  }
  return data;
}, (error) => {
  window.alert(error.msg);
  return Promise.reject(error);
});

export default request;
